@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}